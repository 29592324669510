<template>
    <b-container fluid>
        <b-container fluid>
            <b-col cols="6">
                <dmx-title backlink :bc-items="bcItems">{{(firmwareId !=0) ? name : $t('common_create') }}</dmx-title>
            </b-col>

            <b-row no-gutters>
                <b-col cols="12" class="d-flex align-items-end justify-content-end pb-4">
                    <b-button v-if="firmwareId != 0" variant="danger" v-b-modal.delete-model-modal class="mr-1">
                        {{ $t('common_delete') }}
                    </b-button>
                    <b-button variant="success" @click="saveFirmware()">
                        {{ (firmwareId != 0) ? $t('common_save') : $t('common_create') }}
                    </b-button>
                </b-col>
                </b-row>
        </b-container>


        <b-card style="background-color: #f3f2e8;">
            <!--<b-toolbar dense
                       dark
                       max-height="50"
                       color="grey">
            </b-toolbar>-->
            <b-card-text class="mt-3">
                {{ $t('common_name') }}
                <b-form-input class="mb-2" v-model="name"></b-form-input>
                {{ $t('common_file') }}
                <b-form-file class="mb-2" v-model="file" truncate-length="15" :disabled="firmwareId != 0"></b-form-file>
                {{ $t('common_semver') }}
                <b-form-input class="mb-2" v-model="semanticVersion"></b-form-input>
                {{ $t('common_mqttapi') }}
                <!--<b-form-input class="mb-2" v-model="mqttMessageApiVersion"></b-form-input>-->
                <b-form-select v-model="selectedMqttApiVersion" :options="mqttMessageApiOptions"></b-form-select>
                <template v-if="firmwareId != 0">
                    {{ $t('common_created') }}
                    <b-form-input v-model="createdOnUtc" disabled></b-form-input>
                </template>
            </b-card-text>
            <div style="flex: 1 1 auto;"></div>
        </b-card>

        <b-modal size="md" id="delete-model-modal" title="Delete Model" @ok="deleteFirmware()">
            <p>
                You are about to delete this localeStringResource (<b>{{ name }}</b>).<br />
                <b>This operation cannot be undone!</b>
            </p>

            <template #modal-footer="{ hide, ok }">
                <b-button variant="primary" @click="hide()">
                    {{ $t('common_cancel') }}
                </b-button>
                <b-button variant="danger" @click="ok()">
                    {{ $t('common_delete') }}
                </b-button>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
    import firmwareService from '@/services/firmwareService';
    import router from '@/router';

    export default {
        name: 'EditFirmware',
        props: ["firmwareId"],
        components: {
        },
        data: () => ({
            deleteDialog: false,
            file: null,
            name: '',
            semanticVersion: '',
            selectedMqttApiVersion: null,
            mqttMessageApiOptions: [{ value: null, text: '-- Select Mqtt Api Version --'}],
            fileUploadBase64: '',
            fileSize: 0,
            deleteFile: false,
            createdOnUtc: ''
        }),
        methods: {
            handleFile() {
                //console.log('file', this.file)
				this.createBase64(this.file);
            },
            createBase64(fileObject) {
                const reader = new FileReader();
                reader.onload = (e) => {
					this.fileUploadBase64 = e.target.result;
				}
                reader.readAsDataURL(fileObject);
			},
            deleteFirmware() {
                firmwareService.deleteFirmware(this.firmwareId)
                    .then(data => {
                        router.go(-1)
                    });
            },
            saveFirmware() {
                if (this.firmwareId > 0) {
                    firmwareService.updateFirmware({
                        id: this.firmwareId,
                        name: this.name,
                        semanticVersion: this.semanticVersion,
                        mqttMessageApiVersion: this.selectedMqttApiVersion,
                        fileUploadBase64: this.fileUploadBase64,
						deleteFile: this.deleteFile
                    })
                        .then(data => {
                            router.go(-1)
                        });
                } else {
                    firmwareService.createFirmware({
                        id: this.firmwareId,
                        name: this.name,
                        semanticVersion: this.semanticVersion,
                        mqttMessageApiVersion: this.selectedMqttApiVersion,
						fileUploadBase64: this.fileUploadBase64,
						deleteFile: this.deleteFile
                    })
                        .then(data => {
                            router.go(-1)
                        });
                }
            },
            fillView(data) {
                if (data) {
                    this.name = data.name;
                    this.semanticVersion = data.semanticVersion;
                    this.selectedMqttApiVersion = data.mqttMessageApiVersion;
                    this.fileSize = data.fileSize;
                    this.createdOnUtc = data.createdOnUtc;
                }
            }
        },
        computed: {
            bcItems() {
                return [
                    {
                        text: 'firmware',
                        href: '/firmware',
                    },
                    {
                        text: this.name,
                        href: '/firmware/editFirmware/' + this.firmwareId,
                        active: true,
                    }
                ]
            },
            },
        mounted() {
            firmwareService.getMqttMessageApiVersions()
                .then(data => {
                    data.forEach(version => {
                        this.mqttMessageApiOptions.push({ value: version, text: version });
                    });
                });
            if (this.firmwareId > 0) {
                firmwareService.getFirmware(this.firmwareId)
                    .then(data => {
                        this.fillView(data);
                    });
            }
        },
        watch: {
            file: {
                handler() {
                    this.handleFile()
                }
            },
        },
    }
</script>
